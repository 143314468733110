import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SekcjaPortfolio from "../components/sekcjaportfolioen"
import Helmet from "react-helmet"

import ScrollAnimation from 'react-animate-on-scroll';




const Portfolio = ({ data }) => (
  <>
  <Helmet
  title={data.wordpressPage.acf.portfolio_title_seo} 
  titleTemplate={`%s | ${data.wordpressPage.acf.portfolio_title_seo}`}
  meta={[
    {
      name: `description`,
      content: data.wordpressPage.acf.portfolio_description_seo, 
    },
    {
      property: `og:title`,
      content: data.wordpressPage.acf.portfolio_title_seo,
    },
    {
      name: `thumbnail`,
      content: data.wordpressPage.acf.portfolio_img_seo.localFile.url, 
    },
    {
      property: `og:image`,
      content: data.wordpressPage.acf.portfolio_img_seo.localFile.url, 
    },
    {
      property: `og:description`,
      content: data.wordpressPage.acf.portfolio_description_seo,
    },
    {
      property: `og:type`,
      content: `website`,
    },
  ]}
/>


    <div className="small-banner-content"><div style={{ 
          backgroundImage: `url(${data.wordpressPage.acf.portfolio_banner_img.localFile.url})`}} className="background-small"></div>
          <div className="text-on-banner">  

          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
          <h1 className='smallbanner-title'>{data.wordpressPage.acf.portfolio_title}</h1>
          </ScrollAnimation>
      
        </div>
        <div className='right-bottom-curve-cont'>
          <div className="right-bottom-curve"></div>
        </div>
        </div>


<Layout lang="en" pageInfo={{ pageName: "portfolio" }}>
<SekcjaPortfolio lang="en"/>
</Layout> 
</>
)

export default Portfolio
 export const query = graphql`
 query awwqasewewewewewq {
  wordpressPage(title: {eq: "Portfolio EN"}) {
    acf {
      portfolio_title_seo
      portfolio_description_seo
      portfolio_img_seo {
        localFile {
          url
        }
      }
      portfolio_banner_img {
        localFile {
          url
        }
      }
      portfolio_title
    }
  }
}


 `
